import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { CommonJumbotron } from "../../components/jumbotron";
import BusinessItem from "../../components/BusinessItem";
import ContainerBox from "../../components/containerBox";

const useStyles = makeStyles(theme =>
  createStyles({
    head: {
      color: "white",
      fontWeight: 700,

      [theme.breakpoints.up("xs")]: {
        fontSize: "36px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "56px",
      },
    },
    lead: {
      fontSize: "22px",
      color: "white",
      fontWeight: 400,
    },
    contents: {
      "& section:nth-child(odd)": {
        backgroundColor: "#f8f8f8",
      },
      "& section:nth-child(even)": {
        backgroundColor: "#fff",
      },
    },
    section: {
      padding: "100px 0",

      [theme.breakpoints.up("lg")]: {
        padding: "145px 0",
      },
    },
    // contents
    headerWrapper: {
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",

      "& img": {
        margin: "0",
      },

      "& h2": {
        fontSize: "35px",
        fontWeight: "700",
        marginLeft: "16px",
      },
    },
    descriptionDiv: {
      [theme.breakpoints.up("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        maxWidth: "360px",
      },
    },
    achievement: {
      fontSize: "12px",
      color: "#858585",
      marginBottom: "30px",
    },
    title: {
      fontSize: "24px",
      fontWeight: 700,
      color: "#222222",
      marginBottom: "12px",
    },
    description: {
      fontSize: "16px",
      color: "#858585",
      marginBottom: "12px",

      "&:last-child": {
        marginBottom: "40px",
      },
    },
    logoImageAzar: {
      width: 254,
      height: 62.5,
      maxWidth: "216px",
    },
    logoImageHakuna: {
      width: "100%",
      maxWidth: "318px",
    },
    logoImageHyperX: {
      width: "100%",
      maxWidth: "242px",
    },
    azar: {
      color: "#00dd99",
    },
    hakuna: {
      color: "#ffd033",
    },
    hyperx: {
      color: "#2b3340",
      marginLeft: "0 !important",
    },
  })
);

interface BusinessPageProps {
  pageContext: {
    language: string;
  };
}

function BusinessPage({ pageContext }: BusinessPageProps) {
  const classes = useStyles();
  const intl = useIntl();
  const { language } = pageContext;
  const data = useStaticQuery(graphql`
    query {
      backgroundPlaceholder: file(
        relativePath: { eq: "product/img-title-photo-business.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      azarLogo: file(relativePath: { eq: "product/img-azar-logo@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 216) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      azarScreen2: file(
        relativePath: { eq: "product/img-azar-screen-03@3x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hakunaLogo: file(relativePath: { eq: "product/img-hakuna-logo-01@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hakunaScreen2: file(
        relativePath: { eq: "product/img-hakuna-screen-04@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      epicScreen: file(relativePath: { eq: "product/img-epic@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      epicLogo: file(relativePath: { eq: "product/img-epic-logo@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hyperXScreen1: file(
        relativePath: { eq: "product/img-hyper-x-screen-01@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hyperxLogo: file(
        relativePath: { eq: "product/img-hyper-x-logo@3x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout language={language}>
      <SEO
        title="Product"
        description={intl.formatMessage({ id: "business_description" })}
      />
      <CommonJumbotron
        backgroundImageFluid={data.backgroundPlaceholder.childImageSharp.fluid}
        title={intl.formatMessage({ id: "business_title" })}
        lead={intl.formatMessage({ id: "business_description" })}
      />
      <main className={classes.contents}>
        <section className={classes.section} id="azar">
          <ContainerBox>
            <BusinessItem
              screenImage={data.azarScreen2.childImageSharp.fluid}
              website={intl.formatMessage({ id: "azar_website" })}
              playstore={intl.formatMessage({ id: "azar_playstore" })}
              appstore={intl.formatMessage({ id: "azar_appstore" })}
            >
              <div className={classes.descriptionDiv}>
                <div>
                  <div className={classes.headerWrapper}>
                    <Img
                      fluid={data.azarLogo.childImageSharp.fluid}
                      className={classes.logoImageAzar}
                      alt="azar logo"
                    />
                  </div>
                  <Typography component="p" className={classes.achievement}>
                    {intl.formatMessage({ id: "azar_accomplishment_1" })}
                    <br />
                    {intl.formatMessage({ id: "azar_accomplishment_2" })}
                    <br />
                    {intl.formatMessage({ id: "azar_accomplishment_3" })}
                    <br />
                  </Typography>
                </div>
                <div>
                  <Typography variant="h3" className={classes.title}>
                    {intl.formatMessage({ id: "azar_title" })}
                  </Typography>
                  <Typography component="p" className={classes.description}>
                    {intl.formatMessage({ id: "azar_descrption" })}
                  </Typography>
                </div>
              </div>
            </BusinessItem>
          </ContainerBox>
        </section>

        <section className={classes.section} id="hakuna">
          <ContainerBox>
            <BusinessItem
              screenImage={data.hakunaScreen2.childImageSharp.fluid}
              website={intl.formatMessage({ id: "hakuna_website" })}
              playstore={intl.formatMessage({ id: "hakuna_playstore" })}
              appstore={intl.formatMessage({ id: "hakuna_appstore" })}
            >
              <div className={classes.descriptionDiv}>
                <div>
                  <div className={classes.headerWrapper}>
                    <Img
                      fluid={data.hakunaLogo.childImageSharp.fluid}
                      className={classes.logoImageHakuna}
                      alt="hakuna logo"
                    />
                  </div>
                  <Typography component="p" className={classes.achievement}>
                    {intl.formatMessage({ id: "hakuna_accomplishment_1" })}
                    <br />
                    {intl.formatMessage({ id: "hakuna_accomplishment_2" })}
                    <br />
                    {intl.formatMessage({ id: "hakuna_accomplishment_3" })}
                    <br />
                  </Typography>
                </div>
                <div>
                  <Typography variant="h3" className={classes.title}>
                    {intl.formatMessage({ id: "hakuna_title" })}
                  </Typography>
                  <Typography component="p" className={classes.description}>
                    {intl.formatMessage({ id: "hakuna_description" })}
                  </Typography>
                  <Typography component="p" className={classes.description}>
                    {intl.formatMessage({ id: "hakuna_description_2" })}
                  </Typography>
                </div>
              </div>
            </BusinessItem>
          </ContainerBox>
        </section>

        <section className={classes.section} id="enterprise">
          <ContainerBox>
            <BusinessItem
              screenImage={data.epicScreen.childImageSharp.fluid}
              website={"https://hce.io/"}
              linkedin={"https://www.linkedin.com/company/hcenterprise/"}
            >
              <div className={classes.descriptionDiv}>
                <div>
                  <div className={classes.headerWrapper}>
                    <Img
                      fluid={data.epicLogo.childImageSharp.fluid}
                      className={classes.logoImageHyperX}
                      alt="hyperx logo"
                    />
                  </div>
                </div>
                <div>
                  <Typography variant="h3" className={classes.title}>
                    {intl.formatMessage({ id: "enterprise_title_1" })}
                  </Typography>
                  <Typography component="p" className={classes.description}>
                    {intl.formatMessage({ id: "enterprise_description_1" })}
                  </Typography>
                  <Typography component="p" className={classes.description}>
                    {intl.formatMessage({ id: "enterprise_description_2" })}
                  </Typography>
                </div>
              </div>
            </BusinessItem>
          </ContainerBox>
        </section>

        <section className={classes.section} id="hyper-x">
          <ContainerBox>
            <BusinessItem
              screenImage={data.hyperXScreen1.childImageSharp.fluid}
            >
              <div className={classes.descriptionDiv}>
                <div>
                  <div className={classes.headerWrapper}>
                    <Img
                      fluid={data.hyperxLogo.childImageSharp.fluid}
                      className={classes.logoImageHyperX}
                      alt="hyperx logo"
                    />
                  </div>
                </div>
                <div>
                  <Typography variant="h3" className={classes.title}>
                    {intl.formatMessage({ id: "hyperx_title_1" })}
                  </Typography>
                  <Typography component="p" className={classes.description}>
                    {intl.formatMessage({ id: "hyperx_description_1" })}
                  </Typography>
                  <Typography component="p" className={classes.description}>
                    {intl.formatMessage({ id: "hyperx_description_2" })}
                  </Typography>
                  <Typography component="p" className={classes.description}>
                    {intl.formatMessage({ id: "hyperx_description_3" })}
                  </Typography>
                </div>
              </div>
            </BusinessItem>
          </ContainerBox>
        </section>
      </main>
    </Layout>
  );
}

export default BusinessPage;
