import React from "react";
import Img, { FluidObject } from "gatsby-image";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { AppStore, PlayStore, Home } from "./icons";
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles(theme => createStyles({
  root: {},
  month: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#858585",
  },
  imageCaption: {
    fontSize: "16px",
    color: "#858585",
  },
  imageContainer: {
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  imageWrapper: {
    [theme.breakpoints.up("xs")]: {
      width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  appImage: {
    marginBottom: 0,
    maxWidth: "370px",

    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  businessLink: {
    margin: "8px",
    textDecoration: "none",
    color: "#858585",

    "&:hover": {
      color: "#00dd99",
    },
  },
  linkWrapper: {
    '& > a:first-child': {
      marginLeft: 0,
    },
  }
}));

function BusinessItem({
  children,
  screenImage,
  id,
  website,
  playstore,
  appstore,
  linkedin,
}: BusinessItemProps) {
  const classes = useStyles();

  return (
    <Grid id={id} className={classes.root} container spacing={8}>
      <Grid item lg={6} xs={12}>
        <div className={classes.imageWrapper}>
          <Img
            className={classes.appImage}
            fluid={screenImage}
            alt="second image"
          />
        </div>
      </Grid>
      <Grid item lg={6} xs={12}>
        {children}
        <div className={classes.linkWrapper}>
          {playstore && (
            <a
              href={playstore}
              target="_blank"
              rel="noreferrer"
              className={classes.businessLink}
            >
              <PlayStore />
            </a>
          )}
          {appstore && (
            <a
              href={appstore}
              target="_blank"
              rel="noreferrer"
              className={classes.businessLink}
            >
              <AppStore />
            </a>
          )}
          {website && (
            <a
              href={website}
              target="_blank"
              rel="noreferrer"
              className={classes.businessLink}
            >
              <Home />
            </a>
          )}
          {linkedin && (
            <a
              href={linkedin}
              target="_blank"
              rel="noreferrer"
              className={classes.businessLink}
            >
              <LinkedInIcon />
            </a>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

BusinessItem.defaultProps = {
  id: "",
  website: "",
  playstore: "",
  appstore: "",
};

interface BusinessItemProps {
  children: React.ReactChild;
  screenImage: FluidObject | FluidObject[] | undefined;
  id: string;
  website?: string;
  playstore?: string;
  appstore?: string;
  linkedin?: string;
}

export default BusinessItem;
